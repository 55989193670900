<template>
    <div class="monitor-ycysfbt">
        <div class="selector">
            <el-select v-model="year" placeholder="请选择">
                <el-option
                    v-for="item in years"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
            </el-select>
        </div>
        <div class="download-list">
            <div
                v-for="({ MC, FjList }, index) in files"
                :key="index"
                class="item"
            >
                <div>
                    <span>{{ MC }}</span>
                    <el-checkbox
                        v-show="showCheckBox"
                        v-model="checkedList[index]"
                        @change="
                            (check) => {
                                changeChecked(check, index);
                            }
                        "
                    ></el-checkbox>
                </div>
                <div v-for="(item, index) in FjList" :key="index">
                    <span style="margin-right: 10px">{{ item.WDMC }}</span>
                    <a :href="item.CCLJ" download="" target="_blank">下载</a>
                </div>
            </div>
            <div v-if="!hasFile" class="empty">暂无数据</div>
        </div>
        <div class="map_box">
            <MyMap :myMap.sync="myMap" :mapData="mapData" />
        </div>

        <!-- 图例 -->
        <div class="legend-part flex-y-center">
            <div
                class="legend-item flex-y-center"
                v-for="(item, index) in legendList"
                :key="index"
            >
                <div class="icon" :style="{ background: item.color }"></div>
                <div class="name">{{ item.name }}</div>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart flex scrollbar-hidden">
            <div class="title flex-y-center">图例</div>
            <div class="flex flex-1 list flex-wrap">
                <!-- 遗产要素分布图 -->
                <LegendList
                    :listAry="onOffList"
                    keyValue="ycys"
                    @legendChangeSingle="legendChangeMap"
                ></LegendList>
            </div>
        </div>
    </div>
</template>

<script>
import MyMap from "@comp/map/BaseMap.vue";
import configMap from "../0_com_js/configMap.js";
import LegendList from "@views/big_screen/monitor_all/2_page_center/comp/LegendList.vue"; //图例
export default {
    components: {
        MyMap,
        LegendList,
    },
    mixins: [configMap],
    props: {},
    data() {
        return {
            myMap: null,
            mapData: {
                zoom: 12.6,
                baseMapNum: 0,
                mapTypeBtn: false,
            },
            mc: "遗产总图",
            itemId: 301,
            legendList: [
                {
                    color: "rgb(250,5,5)",
                    name: "遗产区",
                },
                {
                    color: "rgb(10,240,15)",
                    name: "缓冲区",
                },
                {
                    color: "#FD1EFB",
                    name: "保护范围",
                },
                {
                    color: "#4151E5",
                    name: "建控地带",
                },
                {
                    color: "#B9FFF5",
                    name: "古茶林",
                },
                {
                    color: "#FDFF81",
                    name: "传统村落",
                },
                {
                    color: "#68A9FF",
                    name: "分隔防护林",
                },
            ],
        };
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
.monitor-ycysfbt {
    position: relative;
    .map_box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .legend-part {
        padding: 0 20px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 10;
        min-height: 56px;
        background: rgba(4, 28, 67, 0.8);
        .legend-item {
            margin-right: 10px;
            .icon {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                font-weight: 400;
                font-size: 12px;
                color: #d8eaf6;
            }
            .name {
                color: #fff;
            }

            text-align: center;
        }
    }

    .selector {
        left: 45px;
        top: 45px;
        width: 200px;
        position: absolute;
        z-index: 999;
        box-sizing: content-box;
        background: #fff;
        .file-item {
            height: 45px;
            line-height: 45px;
            padding: 0 15px 0 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
            > a {
                display: inline-block;
                padding-left: 25px;
                background-image: url("~@image/working_desk/monitor_data/download.png");
                background-repeat: no-repeat;
                background-position: left center;
                cursor: pointer;
                color: #5ab0f7;
            }
        }
    }
    .download-list {
        left: 45px;
        top: 100px;
        position: absolute;
        z-index: 999;
        .empty {
            height: 45px;
            line-height: 45px;
            padding: 0 30px 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
        }
        .item {
            height: auto;
            padding: 0 15px 0;
            background: #fff;
            &:nth-of-type(n + 2) {
                border-top: 1px solid #ddd;
            }
            div {
                height: 45px;
                line-height: 45px;
                padding: 0 15px 0;
                background: #fff;
                display: flex;
                justify-content: space-between;
                a {
                    display: inline-block;
                    padding-left: 25px;
                    background-image: url("~@image/working_desk/monitor_data/download.png");
                    background-repeat: no-repeat;
                    background-position: left center;
                    cursor: pointer;
                    color: #5ab0f7;
                }
            }
        }
    }
}
</style>
