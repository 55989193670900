<template>
    <div class="flex flex-wrap">
        <div
            v-for="(item, index) in listAry"
            :key="keyValue + index"
            class="flex-y-center"
        >
            <div v-if="item.state && item.legend">
                <div v-if="item.mulList" class="flex-y-center">
                    <div
                        v-for="(itemInner, indexInner) in item.mulList"
                        :key="indexInner"
                        @click="legendChangeMul(itemInner)"
                        class="flex-y-center legendItem pointer"
                    >
                        <img
                            :src="
                                itemInner.imgState
                                    ? itemInner.src
                                    : itemInner.srcDefault
                            "
                            class="img"
                        />
                        <div class="name">{{ itemInner.name }}</div>
                    </div>
                </div>
                <div
                    v-else
                    class="flex-y-center legendItem pointer"
                    @click="legendChangeSingle(item)"
                >
                    <img
                        :src="item.imgState ? item.src : item.srcDefault"
                        class="img"
                    />
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
export default {
    components: {
        OnOff,
    },
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        keyValue: {
            type: String,
            default() {
                return "";
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        legendChangeMul(item) {
            this.$emit("legendChangeMul", item);
        },
        legendChangeSingle(item) {
            this.$emit("legendChangeSingle", item);
        },
    },
};
</script>
<style scoped lang="scss">
.legendItem {
    margin-right: 10px;
    margin-bottom: 5px;
    .img {
        width: 20px;
        margin-right: 5px;
    }
    .name {
        font-weight: 400;
        font-size: 12px;
        color: #d8eaf6;
    }
}
.name {
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
}
</style>
